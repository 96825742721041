import React, { useState, useContext, useEffect, useMemo, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslation } from 'react-i18next';

import useShortTranslation from '../../../../utils/hooks/useShortTranslation';
import usePreferredFeedId from '../../../../utils/hooks/usePreferredFeedId';

import { default as AppContext } from '../../../../contexts/AppContext';
import { default as DashboardContext } from '../../../../contexts/DashboardContext';
import { default as RfpGatewayContext } from '../../../../contexts/RfpGatewayContext';
import { default as InstrumentContext } from '../../../../contexts/InstrumentContext';

import { AccountMarketType, MarketItem } from '../../../../gateways/RfpGateway/rfp.types';

import { default as Nullable, Optional } from '../../../../utils/functions/Nullable';
import { default as usePromiseFactory } from '../../../../utils/hooks/usePromiseFactory';
import { default as useObservable } from '../../../../utils/hooks/useObservable';
import { default as useForceRerender } from '../../../../utils/hooks/useForceRerender';

import { DEFAULT_FEED_ID, instrumentExistsInDynamicWatchlists } from '../../../../utils/functions/WatchlistUtils';

import useSelectedTradingAccount from '../../../../utils/hooks/useSelectedTradingAccount';

import tradingAccountStore from '../../../../store/tradingAccountStore';

import TradeBoardHeader from './TradeBoardHeader';
import cn from 'classnames';
import styles from './TradeBoard.module.scss';
import TradeBoardItem from './TradeBoardItem';
import { useDynamicWatchLists } from '../../../../utils/hooks/useDynamicWatchLists';

const TradeBoard = () => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const instrumentContext = useContext(InstrumentContext);
	const rfpGatewayContext = useContext(RfpGatewayContext);
	const isFundedTrader = tradingAccountStore.use.isFundedTrader();

	const promiseFactory = usePromiseFactory();
	const forceRerender = useForceRerender();
	const currentWatchList = tradingAccountStore.use.currentWatchList();
	const selectedInstrument = dashboardContext.selectedInstrument;
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();

	const dynamicWatchLists = useDynamicWatchLists();
	const [isDynamicWatchlist, setIsDynamicWatchlist] = useState(false);
	const { t } = useTranslation();
	const tt = useShortTranslation('en:');
	const oneClickTrading = dashboardContext.oneClickTrading;

	const [isWatchlistSearchOpen, setIsWatchlistSearchOpen] = useState(false);

	const instrumentListRef = useRef<Optional<Array<{ _code: string }>>>();

	const activeTradingAccount = useSelectedTradingAccount();

	const preferredFeedId = usePreferredFeedId(activeTradingAccount);

	useObservable(appContext.getPropertyChangeStream('appTheme', 'email'), (change) => {
		promiseFactory.throttle('appContext.propertyChanged', 100).then(() => {
			forceRerender();
		});
	});

	// TODO: - Double check for what we need to listen here
	useObservable(
		dashboardContext.getPropertyChangeStream(
			'marketItems',
			'accountType',
			'loadingState',
			'tradingAccount',
			'watchlistAccount',
			'accountChange',
			'notification',
			'watchlist',
			'mappedWatchlist',
			'equityWatchlist',
			'lastSymbol',
			'selectedSymbol',
			'tradingPositions',
			'newAccount',
			'chartParameters',
			'gridViewCharts',
			'toggleSort',
			'watchListStoredAsArray',
			'accountIndex',
			'selectedInstrument',
			'defaultWatchLists'
		),
		() => {
			promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => {
				forceRerender();
			});
		}
	);

	useEffect(() => {
		if (dashboardContext.defaultWatchLists.length > 0) {
			if (
				instrumentExistsInDynamicWatchlists(
					currentWatchList,
					dashboardContext.defaultWatchLists,
					isSpreadBettingAccount
				)
			) {
				setIsDynamicWatchlist(true);
			} else {
				setIsDynamicWatchlist(false);
			}
		}
	}, [dashboardContext.defaultWatchLists, currentWatchList, isSpreadBettingAccount]);

	useEffect(() => {
		Nullable.of(currentWatchList)
			.filter((_) => dashboardContext.accountType !== 'equity' && dashboardContext.mappedWatchlist !== null)
			.run((watchlistName) => {
				const instruments: Array<{ _code: string }> = [];
				if (dashboardContext.mappedWatchlist !== null) {
					Nullable.of(dashboardContext.mappedWatchlist[watchlistName]).map((symbols) => {
						symbols.forEach((symbol) => {
							instruments.push(symbol);
						});
					});
				}
				instrumentListRef.current = instruments;
				subscribeToNewInstruments();
			});
		return () => {
			unsubscribeInstruments();
		};
	}, [
		preferredFeedId,
		currentWatchList,
		instrumentListRef,
		dashboardContext.watchlist,
		dashboardContext.mappedWatchlist,
		dashboardContext.accountType,
		dashboardContext.marketItems,
	]);

	useEffect(() => {
		if (dashboardContext.defaultWatchLists.length > 0) {
			if (isFundedTrader) {
				dynamicWatchLists(dashboardContext.watchlist.slice(dashboardContext.defaultWatchLists.length), isFundedTrader);
			} else {
				dynamicWatchLists(dashboardContext.watchlist, isFundedTrader);
			}
		}
	}, [dashboardContext.defaultWatchLists, isFundedTrader]);

	useEffect(() => {
		if (
			rfpGatewayContext?.mapMarketItems.size !== 0 &&
			(!selectedInstrument ||
				(isSpreadBettingAccount && selectedInstrument.accountMarketType !== AccountMarketType.SpreadBetting) ||
				(!isSpreadBettingAccount && selectedInstrument.accountMarketType === AccountMarketType.SpreadBetting))
		) {
			const initialChartSymbol = isSpreadBettingAccount ? { code: 'GBPUSD_SB' } : { code: 'GBPUSD' };
			const marketItem = rfpGatewayContext?.getMarketItem(initialChartSymbol.code, DEFAULT_FEED_ID);
			if (marketItem) {
				dashboardContext.selectedInstrument = marketItem;
			}
		}
	}, [isSpreadBettingAccount, rfpGatewayContext?.mapMarketItems.size]);

	const instrumentHandlers = useMemo(() => {
		return {
			// TODO: - Check onRemovalFromWatchlist - can we use it for something? Otherwise - remove/refactor.
			onRemovalFromWatchlist: (marketItem: MarketItem, itemsForRemoval: any) => {
				if (dashboardContext.mappedWatchlist) {
					const mappedWatchlist = dashboardContext.mappedWatchlist;

					const deletionItems = Array.isArray(marketItem) ? marketItem : [marketItem];

					if (mappedWatchlist !== null) {
						let instruments = mappedWatchlist[currentWatchList];
						if (instruments !== null && Array.isArray(instruments)) {
							const startingLength = instruments.length;
							instruments = instruments.filter((instrument) => {
								return !deletionItems.some((item) => item._code === instrument._code);
							});
							if (instruments.length !== startingLength) {
								mappedWatchlist[currentWatchList] = instruments;
								return mappedWatchlist;
							}
						}
					}
				}

				dashboardContext.openInformation = -1;
			},
			onInstrumentChange: (marketItem: MarketItem) => {
				// TODO: - Refactor when we rewrite the order ticket
				dashboardContext.symbolChanged = marketItem.code;
				dashboardContext.gridChartsChanged = true;
				dashboardContext.selectedPosition = null;
				dashboardContext.selectedInstrument = marketItem;
				dashboardContext.selectedType = 'Instrument';
				dashboardContext.closeAllOtherTabs();
				dashboardContext.isEdit = false;

				//update recently viewed instruments queue
				Nullable.of(instrumentContext.instruments.find((value) => value.code === marketItem.code)).run((instrument) => {
					instrumentContext.recentlyViewedInstruments.delete(instrument);
					instrumentContext.recentlyViewedInstruments.enqueue(instrument);
				});
			},
		};
	}, [dashboardContext.accountType, rfpGatewayContext, activeTradingAccount]);

	const subIdRef = useRef<string | undefined>(undefined);

	const subscribeToNewInstruments = () => {
		if (rfpGatewayContext && dashboardContext.marketItems.length > 0) {
			// unsubscribe previous quotes
			if (instrumentListRef.current) {
				if (subIdRef.current) {
					rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
					subIdRef.current = undefined;
				}

				const symbols = instrumentListRef.current.map((item) => item._code);

				// subscribe for price quote
				const subId = rfpGatewayContext.subscribePriceQuote(DEFAULT_FEED_ID, symbols, (priceQuote) => {});

				subIdRef.current = subId;
			}
		}
	};

	const unsubscribeInstruments = () => {
		// unsubscribe price quote on unmount
		return () => {
			if (rfpGatewayContext && subIdRef.current) {
				rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
				subIdRef.current = undefined;
			}
		};
	};

	const handleWatchlistSearchToggle = () => {
		setIsWatchlistSearchOpen(!isWatchlistSearchOpen);
	};

	return (
		<div className={styles.container}>
			<TradeBoardHeader
				isWatchlistSearchOpen={isWatchlistSearchOpen}
				handleWatchlistSearchToggle={handleWatchlistSearchToggle}
				isDynamicWatchlist={isDynamicWatchlist}
			/>
			{currentWatchList &&
				dashboardContext.watchlist &&
				dashboardContext.mappedWatchlist &&
				dashboardContext.mappedWatchlist[currentWatchList] && (
					<div className={styles.watchlistContainer}>
						{dashboardContext.mappedWatchlist[currentWatchList].length > 0 && (
							<div
								className={
									dashboardContext.mappedWatchlist[currentWatchList].length > 5 && oneClickTrading
										? styles.marketTickerContainer
										: cn(styles.marketTickerContainer, styles.noScrollBar)
								}
							>
								<div className={styles.instrumentsContainer}>
									{dashboardContext.mappedWatchlist[currentWatchList].map((instrument, key) => {
										const mktItem = rfpGatewayContext?.getMarketItem(instrument._code);
										if (mktItem) {
											return (
												<TradeBoardItem
													key={`${mktItem.code}-keep-this-here-and-do-not-use-indices-as-keys-pls-${activeTradingAccount?.id}`}
													marketItem={mktItem}
													onInstrumentChange={() => instrumentHandlers.onInstrumentChange(mktItem)}
													isDynamicWatchlist={isDynamicWatchlist}
												/>
											);
										}
									})}
								</div>

								{!isDynamicWatchlist && (
									<div className={styles.addInstrumentContainer}>
										<div
											className={
												dashboardContext.mappedWatchlist !== null &&
												Object.keys(dashboardContext.mappedWatchlist).length === 0
													? styles.centeredText
													: styles.addInstrument
											}
											onClick={() => handleWatchlistSearchToggle()}
										>
											<FontAwesomeIcon icon={['far', 'plus-circle']} className={styles.addInstrumentIcon} />
											<span className={styles.addInstrumentTitle}>{tt('ADD_INSTRUMENT')}</span>
										</div>
									</div>
								)}
							</div>
						)}

						{dashboardContext.mappedWatchlist[currentWatchList].length === 0 && (
							<div className={styles.emptyContainer}>
								<div className={styles.emptyStar}>
									<FontAwesomeIcon icon={['fas', 'tasks']} className={styles.iconC} aria-hidden="true" />
								</div>
								<div className={styles.emptyTitle}>{t('wtr:WTR_EMPTY_WATCHLIST')}</div>
								<div className={styles.addInstrumentContainer}>
									<div className={styles.centeredText} onClick={() => handleWatchlistSearchToggle()}>
										<FontAwesomeIcon icon={['far', 'plus-circle']} className={styles.addInstrumentIcon} />
										<span className={styles.addInstrumentTitle}>{tt('ADD_INSTRUMENT')}</span>
									</div>
								</div>
							</div>
						)}
					</div>
				)}
		</div>
	);
};

export default TradeBoard;
