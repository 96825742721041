import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Dropdown, DropdownProps } from 'semantic-ui-react';
import moment from 'moment-timezone';

import cn from 'classnames';

import Button from '../../../../shared/Button/Button';
import Modal from '../../../../shared/Modal/Modal';
import ConditionalWrapper from '../../../../shared/ConditionalWrapper';

import {
	EditOrderRequest,
	EditPositionRequest,
	Instrument,
	NewOrderRequest,
	NewPositionRequest,
	PriceQuote,
	TradingAccount,
	TradingPositionState,
} from '../../../../gateways/RfpGateway/rfp.types';

import DashboardContext from '../../../../contexts/DashboardContext';
import RfpGatewayContext from '../../../../contexts/RfpGatewayContext';
import AppContext from '../../../../contexts/AppContext';

import orderStore from '../../../../store/OrderStore/orderStore';
import { ActionType } from '../../../../store/OrderStore/orderStore.types';

import {
	checkIsVolumeUnlimited,
	displayVolumeInfo,
	getGeneralFormatDate,
} from '../../../../utils/functions/subscriptionUtils';
import { Optional } from '../../../../utils/functions/Nullable';
import {
	calcInitialLimitValue,
	calcPipCost,
	calculateMarginImpact,
	fillQuantity,
	getAdditionalSubscriptionPairs,
	getEffectiveSize,
	getHedgedTradeQuantity,
	getMarginCalculationType,
	getMarketItemPipSize,
	preCalcPL,
	TEffectiveSize,
} from '../../../../utils/functions/calculations';
import { translateDate } from '../../../../utils/functions/translateDate';

import BidAskSpread from '../../../components/BidAskSpread/BidAskSpread';
import InstrumentHeader from '../../../components/InstrumentSummary/InstrumentHeader';

import {
	MarginAccountType,
	TicketLayout,
	TradingPositionLimitType,
	TradingPositionSide,
	TradingPositionType,
} from '../../../../utils/functions/enums';

import { RFP } from '../../../../gateways/RfpGateway/rfpConstants';

import useSubscriptionInfo from '../../../../utils/hooks/useSubscriptionInfo';
import useShortTranslation from '../../../../utils/hooks/useShortTranslation';
import useObservable from '../../../../utils/hooks/useObservable';
import usePromiseFactory from '../../../../utils/hooks/usePromiseFactory';
import useForceRerender from '../../../../utils/hooks/useForceRerender';

import DraggableModalDialog from '../../../../shared/Modal/DraggableModalDialog';

import CloseButton from '../../../components/CloseButton/CloseButton';

import ModalOrderInformation from '../ChartPanel/NewOrderModals/OrderTicketModal/OrderInformation/ModalOrderInformation';

import { GymTradingManager } from '../../../../pages/TradersGym/Accounts/GymTradingManager';
import { GymTradingPosition } from '../../../../pages/TradersGym/Positions/GymTradingPosition';
import { TradersGymContext, TradersGymContextType } from '../../../../pages/TradersGym/TradersGymContext';

import WtrPopup from '../../../components/WtrPopup/WtrPopup';

import useMoneyFormatter from '../../../../utils/hooks/useMoneyFormatter';
import useUpdateUserPrefPips from '../../../../utils/mutations/useUpdateUserPrefPips';
import useSelectedTradingAccount from '../../../../utils/hooks/useSelectedTradingAccount';

import pipStore from '../../../../store/PipsStore/pipStore';
import tradingAccountStore from '../../../../store/tradingAccountStore';
import useOrderTicketAccess from '../../../../utils/hooks/useOrderTicketAccess';

import TradeTicketCalendar from './TradeTicketCalendar';

import NumberInput from './NumberInput';
import LimitProfitLoss from './LimitProfitLoss';

import 'react-datepicker/dist/react-datepicker.css';
import tradingViewStore from '../../../../store/tradingViewStore';
import authStore, { AuthStore } from '../../../../store/authStore';
import useSaveUserPreferences from '../../../../utils/mutations/useSaveUserPreferences';
import MarginRequirementsContext from '../../../../contexts/MarginRequirementsContext';
import MarginRulesContext from '../../../../contexts/MarginRulesContext';

import styles from './TradeTicket.module.scss';

export const INITIAL_LIMIT_STOP_PERCENTAGE: number = 0.25;

const TradeTicket = () => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const rfpGatewayContext = useContext(RfpGatewayContext);
	const marginRequirementsContext = useContext(MarginRequirementsContext);
	const marginRulesContext = useContext(MarginRulesContext);
	const { futureMarginValue } = marginRulesContext;

	const gymContext = useContext(TradersGymContext) as TradersGymContextType;
	const { tradersGymContext } = gymContext;
	const isJapanSubscriptionAccount = tradingAccountStore.use.isJapanSubscription();

	const realTradingAccount = useSelectedTradingAccount();
	const gymTradingAccount = tradersGymContext.gymTradingAccount;

	const [selectedTradingAccount, setSelectedTradingAccount] = useState<TradingAccount>(realTradingAccount);

	const updateUserPrefPips = useUpdateUserPrefPips();

	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const current = orderStore((state) => state.current);
	const action = orderStore((state) => state.action);
	const tradingPosition = orderStore((state) => state.tradingPosition);
	const marketItem = orderStore((state) => state.marketItem);
	const takeProfitPips = pipStore.use.takeProfit();
	const stopLossPips = pipStore.use.stopLoss();
	const { side } = current;
	const setTicketLayout = tradingViewStore.use.setTicketLayout();
	const ticketLayout = tradingViewStore.use.ticketLayout();

	const setTradeProps = orderStore.use.setTradeProps();
	const setAction = orderStore.use.setAction();
	const resetOrderStore = orderStore.use.reset();
	const setAmountInfo = orderStore.use.setAmountInfo();
	const setOpenPriceInfo = orderStore.use.setOpenPriceInfo();
	const setStopLossInfo = orderStore.use.setStopLossInfo();
	const setTakeProfitInfo = orderStore.use.setTakeProfitInfo();
	const setMarketItem = orderStore.use.setMarketItem();
	const setCurrentQuote = orderStore.use.setCurrentQuote();
	const createTradeRequestObject = orderStore.use.createTradeRequestObject();
	const setTradingInstrument = orderStore.use.setTradingInstrument();
	const resetExceptSide = orderStore.use.resetExceptSide();
	const { email } = authStore((store: AuthStore) => store.userProfile);
	const isSignalOrder = orderStore.use.isSignalOrder();

	const { mutate: savePreferences } = useSaveUserPreferences();

	const orderType = current.type;
	const isStopLossActive = current.stopLossInfo?.isActive ?? false;
	const isTakeProfitActive = current.takeProfitInfo?.isActive ?? false;
	const selectedMarketItem = marketItem;
	const isCancellingOrder = useRef<boolean>(false);
	const isLiveMode = authStore((store: AuthStore) => store.isLiveMode);

	const { t } = useTranslation();
	const tt = useShortTranslation('en:');

	useEffect(() => {
		if (tradersGymContext.isActive && gymTradingAccount) {
			setSelectedTradingAccount(gymTradingAccount);
		} else {
			setSelectedTradingAccount(realTradingAccount);
		}
	}, [realTradingAccount, gymTradingAccount]);

	const formatAsMoney = useMoneyFormatter(selectedTradingAccount);
	const openOrderInformation = tradingViewStore.use.openOrderInformation();

	const {
		quantityType,
		selectedInstrument,
		instrumentLastTrade,
		oneClickTrading,
		newOrderModalToggle,
		detailedInformation,
		accountValues,
	} = dashboardContext;

	const { orderTicket } = newOrderModalToggle;

	const { languageSettings, isJapanAccount, isArabic } = appContext;

	const [currentPriceQuote, setCurrentPriceQuote] = useState<PriceQuote>();
	const [quantityValues, setQuantityValues] = useState<number[]>([]);
	const [hedgedSquareQuantity, setHedgedSquareQuantity] = useState(false);
	const [calculatedEffectiveSize, setCalculatedEffectiveSize] = useState<TEffectiveSize>({ val: 0, majoritySide: '' });

	// TODO: CHeck how to replace this with accountStats from same store, from this account we can't know if is SB account, only from the stats
	const tradingAccounts = tradingAccountStore.use.accounts();

	const [additionalSubscriptionPairValue, setAdditionalSubscriptionPairValue] = useState<PriceQuote | undefined>(
		undefined
	);

	const defaultGTC = t('wtr:GOOD_TILL_CANCEL');

	const [showCalendar, setShowCalendar] = useState<boolean>(false);
	const [selectedDate, setSelectedDate] = useState<Date>(new Date());
	const [dateText, setDateText] = useState<string>(defaultGTC);

	const [potentialProfitColor, setPotentialProfitColor] = useState<string>('');
	const [potentialLossColor, setPotentialLossColor] = useState<string>('');

	const [pipCost, setPipCost] = useState<string>('N/A');
	const [marginUsage, setMarginUsage] = useState<string>('N/A');

	const [unhedgedMarginUsage, setUnhedgedMarginUsage] = useState<string>('N/A');
	const [marginCalculationType, setMarginCalculationType] = useState(0);

	const [freeMargin, setFreeMargin] = useState<string>('N/A');
	const [prevMarketItemCode, setPrevMarketItemCode] = useState<string | undefined>(undefined);

	const insufficientMargin = useRef<boolean>(false);

	const subIdRef = useRef<string | undefined>(undefined);

	const [subscriptionInfo] = useSubscriptionInfo();

	const orderTicketAccess = useOrderTicketAccess();
	const promiseFactory = usePromiseFactory();
	const forceRerender = useForceRerender();

	const isTraderGymActive = tradersGymContext.isActive;

	const datePickerLocale = useMemo(() => {
		switch (languageSettings) {
			case 'zh-Hans':
			case 'zh-Hant':
				return 'zh-CN';
			default:
				return languageSettings;
		}
	}, [languageSettings]);

	// detailed information is set when the trading instruments for an account are received
	// use it to update the ticket so the configuration is valid
	useObservable(
		dashboardContext.getPropertyChangeStream(
			'quantityType',
			'detailedInformation',
			'showOrderTicket',
			'newOrderModalToggle',
			'showNewsFeed',
			'showOrderInformation',
			'showCloseTicket',
			'showCancelTicket',
			'accountValues'
		),
		async () => {
			await promiseFactory.throttle('dashboardContext.propertyChanged', 100);
			forceRerender();
		}
	);

	const isOneClickTradingEnabled = () => {
		// One Click trading could be active only for normal mode
		return tradersGymContext.isActive ? false : oneClickTrading;
	};

	let currentDate = () => translateDate(new Date(), languageSettings) as moment.Moment;

	const nonArabicFormattedQuickDates = {
		day: appContext.isJapanAccount
			? getGeneralFormatDate(currentDate().unix() * 1000, false, true)
			: currentDate().format('D/MMM/YYYY'),
		week: appContext.isJapanAccount
			? getGeneralFormatDate(currentDate().add(7, 'days').unix() * 1000, false, true)
			: currentDate().add(7, 'days').format('D/MMM/YYYY'),
		month: appContext.isJapanAccount
			? getGeneralFormatDate(currentDate().add(1, 'months').unix() * 1000, false, true)
			: currentDate().add(1, 'months').format('D/MMM/YYYY'),
	};

	const calendarOptions = isArabic
		? [
				{ text: `${currentDate().format('D/MMM/YYYY')} ${t('wtr:EXPIRY_DAY')}`, value: 'EXP_DAY' },
				{ text: `${currentDate().add(7, 'days').format('D/MMM/YYYY')} ${t('wtr:WEEK')} `, value: 'EXP_WEEK' },
				{ text: `${currentDate().add(1, 'months').format('D/MMM/YYYY')} ${t('wtr:MONTH')} `, value: 'EXP_MONTH' },
				{ text: t('wtr:GOOD_TILL_CANCEL'), value: 'EXP_GTC' },
		  ]
		: [
				{ text: `${t('wtr:EXPIRY_DAY')} ${nonArabicFormattedQuickDates.day}`, value: 'EXP_DAY' },
				{ text: `${t('wtr:WEEK')} ${nonArabicFormattedQuickDates.week}`, value: 'EXP_WEEK' },
				{ text: `${t('wtr:MONTH')} ${nonArabicFormattedQuickDates.month}`, value: 'EXP_MONTH' },
				{ text: t('wtr:GOOD_TILL_CANCEL'), value: 'EXP_GTC' },
		  ];

	const orderTypeOptions = useMemo(
		() => [
			{ text: t('en:MARKET_ORDER_TYPE'), value: TradingPositionType.Market },
			{ text: t('en:LIMIT_ORDER_TYPE'), value: TradingPositionType.Limit },
			{ text: t('en:STOP_ORDER_TYPE'), value: TradingPositionType.Stop },
		],
		[]
	);

	useEffect(() => {
		if (marketItem && selectedInstrument && marketItem.code !== selectedInstrument.code) {
			// Close the ticket if limitsOnly (tp/sl) state is true and the instrument has been changed
			if (current.limitsOnly?.length) {
				handleCancelButtonClick();
			}

			resetOrderStore();
			setMarketItem(selectedInstrument);
		} else if (marketItem === undefined && selectedInstrument) {
			resetExceptSide();
			setMarketItem(selectedInstrument);
		}
	}, [selectedInstrument, marketItem]);

	// If there is a price as 2.56400000004 will be normalize as 2.5640
	const gymNormalizePrice = (price: number, decPrec: number | undefined) => (decPrec ? +price.toFixed(decPrec) : price);

	// Will normalize priceQuote if there is a price as 2.56400000004 will be normalize as 2.5640
	const gymNormalizePriceQuote = (priceQuote: PriceQuote, decPrec: number | undefined) => {
		if (decPrec) {
			priceQuote.a = gymNormalizePrice(priceQuote.a, decPrec);
			priceQuote.b = gymNormalizePrice(priceQuote.b, decPrec);
			priceQuote.h = gymNormalizePrice(priceQuote.h, decPrec);
			priceQuote.l = gymNormalizePrice(priceQuote.l, decPrec);
		}
		return priceQuote;
	};

	useEffect(() => {
		if (tradersGymContext.priceQuote) {
			setCurrentPriceQuote(gymNormalizePriceQuote(tradersGymContext.priceQuote, selectedMarketItem?.decPrec));
		}
	}, [tradersGymContext.priceQuote]);

	useEffect(() => {
		if (!orderTicketAccess() && !tradingPosition) {
			dashboardContext.showOrderTicket = false;
			resetOrderStore();
		}
	}, []);

	useEffect(() => {
		if (rfpGatewayContext && selectedMarketItem) {
			// unsubscribe previous quotes
			if (subIdRef.current) {
				rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
			}
			const feedId = selectedMarketItem.feedId;
			const code = selectedMarketItem.code;
			let subCodes: string[] = [code];

			addAdditionalSubPairsCodes(subCodes);

			if (tradersGymContext.isActive) {
				// Update latest priceQuote
				const lastPriceQuote = gymContext.getLastPriceQuote();
				if (lastPriceQuote && lastPriceQuote.c === code) {
					setCurrentPriceQuote(gymNormalizePriceQuote(lastPriceQuote, selectedMarketItem?.decPrec));
				}
			}

			// subscribe for price quote
			subIdRef.current = rfpGatewayContext.subscribePriceQuote(feedId, subCodes, (priceQuote) => {
				if (priceQuote.c === selectedMarketItem.code) {
					if (!tradersGymContext.isActive) {
						setCurrentPriceQuote(priceQuote);
					}
				} else {
					setAdditionalSubscriptionPairValue(priceQuote);
				}
			});

			if (tradingPosition && tradingPosition.state === TradingPositionState.pending && tradingPosition.eT) {
				handleDateSelection(new Date(tradingPosition.eT));
			}
		}

		// unsubscribe price quote on unmount
		return () => {
			if (rfpGatewayContext && subIdRef.current) {
				rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
				subIdRef.current = undefined;
			}
		};
	}, [selectedMarketItem, rfpGatewayContext, orderTicket]);

	useEffect(() => {
		if (additionalSubscriptionPairValue) {
			updatePipCost();
			updateMarginImpact();
		}
	}, [additionalSubscriptionPairValue]);

	useEffect(() => {
		updateMarginImpact();
	}, [currentPriceQuote, current?.type, side, tradingAccounts, selectedTradingAccount]);

	useEffect(() => {
		updatePipCost();
		updateMarginImpact();
	}, [
		current?.amountInfo?.amount,
		selectedMarketItem,
		additionalSubscriptionPairValue,
		tradingAccounts,
		selectedTradingAccount,
	]);

	useEffect(() => {
		updateFreeMargin();
	}, [selectedTradingAccount?.freeMargin, tradingAccounts, selectedTradingAccount]);

	useEffect(() => {
		if (selectedMarketItem && currentPriceQuote?.c === selectedMarketItem.code) {
			if (tradingPosition?.state === TradingPositionState.open) {
				return;
			}

			if (!isCancellingOrder.current) {
				let openPrice: number | undefined = undefined;
				if (current.type === TradingPositionType.Market) {
					openPrice = side === TradingPositionSide.Buy ? currentPriceQuote.a : currentPriceQuote.b;
				} else if (tradingPosition && tradingPosition.state === TradingPositionState.pending) {
					openPrice = current.openPriceInfo?.price;
				}

				if (openPrice) {
					const error = validateOpenPrice(openPrice) ? undefined : t('en:INVALID_VALUE');
					setOpenPriceInfo({ isActive: true, price: openPrice, error: error });
					setCurrentQuote(currentPriceQuote);
				}
			}
		}
	}, [currentPriceQuote, current.openPriceInfo?.price]);

	useEffect(() => {
		if (selectedMarketItem) {
			const isSymbolChanged = prevMarketItemCode !== selectedMarketItem.code;
			if (isSymbolChanged) {
				setPrevMarketItemCode(selectedMarketItem.code);
			}

			// Will trigger trading instrument update
			let instrument: Instrument | undefined = undefined;
			if (selectedTradingAccount && selectedTradingAccount.tradingInstruments) {
				instrument = selectedTradingAccount.tradingInstruments.instruments[selectedMarketItem.code];
				setTradingInstrument(instrument);
				setAmountInfo({
					rndLot: instrument?.rndLot,
				});
			}

			// Setup initial state if selected symbol changed
			if (tradingPosition === undefined) {
				// Setup initial amount
				if (instrument) {
					setAmountInfo({
						min: instrument.minAmount,
						max: instrument.maxAmount,
						step: instrument.stepAmount,
						rndLot: instrument.rndLot,
						error: undefined,
					});

					if (current.amountInfo?.amount === undefined) {
						const localPrefs = localStorage.getItem(`userPref-${email}`);

						let amount = instrument.minAmount;
						if (localPrefs !== null && localPrefs !== 'null') {
							const parsedPrefs = JSON.parse(localPrefs);
							amount =
								parsedPrefs.user_prefs.trading.settings[dashboardContext.accountType]?.amount?.[instrument.code] ??
								instrument.minAmount;
						}
						setAmountInfo({
							amount: amount,
						});
					}
				}

				setInitialQuantityGroup();
			}

			// update amount info only for new orders / positions and not for edit state

			if (
				tradingPosition?.state !== TradingPositionState.pending &&
				tradingPosition?.state !== TradingPositionState.open
			) {
				updateAmountInfo();
			}

			updatePipCost();
			updateMarginImpact();
		}
	}, [selectedMarketItem, selectedTradingAccount, detailedInformation]);
	// detailed information is set when the trading instruments for an account are received
	// use it to update the ticket so the configuration is valid

	useEffect(() => {
		setTradeProps({ accountId: selectedTradingAccount?.id });
	}, [selectedTradingAccount, tradingAccounts, current.accountId]);

	useEffect(() => {
		setInitialQuantityGroup();
	}, [quantityType]);

	useEffect(() => {
		switch (action) {
			case ActionType.cancel:
				handleCancelButtonClick();

				// The Action must be reset after use it
				setAction(ActionType.unknown);
				break;
		}
	}, [action]);

	useEffect(() => {
		if (current.openPriceInfo?.isActive) {
			switch (current.type) {
				case TradingPositionType.Limit:
				case TradingPositionType.Stop:
					const openPrice = calculateLimitStopPrice();
					if (openPrice) {
						const error = validateOpenPrice(openPrice) ? undefined : t('en:INVALID_VALUE');
						setOpenPriceInfo({ isActive: true, price: openPrice, error: error });
					}
					break;
				case TradingPositionType.Market:
					if (currentPriceQuote) {
						const openPrice = side === TradingPositionSide.Buy ? currentPriceQuote.a : currentPriceQuote.b;
						const error = validateOpenPrice(openPrice) ? undefined : t('en:INVALID_VALUE');
						setOpenPriceInfo({ isActive: true, price: openPrice, error: error });
					}
					break;
			}
		}
	}, [current.type, side, current.openPriceInfo?.isActive]);

	useEffect(() => {
		if (!isTakeProfitActive && !tradingPosition?.state) {
			setTakeProfitInfo({ price: undefined, error: undefined });
		}
		if (isTakeProfitActive && current.takeProfitInfo?.price === undefined && side && current.openPriceInfo) {
			const isBuy = side === TradingPositionSide.Buy;
			const price = getOpenPrice() ?? NaN;
			const takeProfit = calcInitialLimitValue({
				price,
				isBuy,
				type: TradingPositionLimitType.TakeProfit,
				pipSize: getMarketItemPipSize(selectedMarketItem),
				defaultPips: isNaN(takeProfitPips) ? 30 : takeProfitPips,
			});
			const potentialPL = calcPotentialProfitLoss(takeProfit, TradingPositionLimitType.TakeProfit);
			const error = validateTakeProfit(takeProfit) ? undefined : t('en:INVALID_VALUE');
			setTakeProfitInfo({ price: takeProfit, potentialProfitLoss: potentialPL, error: error });
		}
	}, [isTakeProfitActive, selectedMarketItem, tradingPosition]);

	useEffect(() => {
		const takeProfit = current.takeProfitInfo?.price;
		if (isTakeProfitActive && takeProfit) {
			const potentialPL = calcPotentialProfitLoss(takeProfit, TradingPositionLimitType.TakeProfit);
			const error = validateTakeProfit(takeProfit) ? undefined : t('en:INVALID_VALUE');
			setTakeProfitInfo({ potentialProfitLoss: potentialPL, error: error });
		}
	}, [
		current.amountInfo?.amount,
		current.takeProfitInfo?.price,
		current.openPriceInfo?.price,
		currentPriceQuote,
		side,
	]);

	useEffect(() => {
		if (!isStopLossActive && !tradingPosition?.state) {
			setStopLossInfo({ price: undefined, error: undefined });
		}
		if (isStopLossActive && current.stopLossInfo?.price === undefined && side && current.openPriceInfo) {
			const isBuy = side === TradingPositionSide.Buy;
			const price = getOpenPrice() ?? NaN;
			const stopLoss = calcInitialLimitValue({
				price,
				isBuy,
				type: TradingPositionLimitType.StopLoss,
				pipSize: getMarketItemPipSize(selectedMarketItem),
				defaultPips: isNaN(stopLossPips) ? 30 : stopLossPips,
			});
			const potentialPL = calcPotentialProfitLoss(stopLoss, TradingPositionLimitType.StopLoss);
			const error = validateStopLoss(stopLoss) ? undefined : t('en:INVALID_VALUE');
			setStopLossInfo({ price: stopLoss, potentialProfitLoss: potentialPL, error: error });
		}
	}, [isStopLossActive, current.stopLossInfo?.price, selectedMarketItem]);

	useEffect(() => {
		const stopLoss = current.stopLossInfo?.price;
		if (isStopLossActive && stopLoss) {
			const potentialPL = calcPotentialProfitLoss(stopLoss, TradingPositionLimitType.StopLoss);
			const error = validateStopLoss(stopLoss) ? undefined : t('en:INVALID_VALUE');
			setStopLossInfo({ potentialProfitLoss: potentialPL, error: error });
		}
	}, [current.amountInfo?.amount, current.stopLossInfo?.price, current.openPriceInfo?.price, currentPriceQuote, side]);

	const validateOpenPrice = (openPrice: number) => {
		if (currentPriceQuote && openPrice > 0) {
			const isBuy = side === TradingPositionSide.Buy;
			const currentPrice = isBuy ? currentPriceQuote.a : currentPriceQuote.b;

			switch (current.type) {
				case TradingPositionType.Limit:
					return isBuy ? currentPrice > openPrice : currentPrice < openPrice;
				case TradingPositionType.Stop:
					return isBuy ? currentPrice < openPrice : currentPrice > openPrice;
				case TradingPositionType.Market:
					return true;
				default:
					break;
			}
		}
		return false;
	};

	const getOpenPrice = () => {
		if (tradingPosition && tradingPosition.state === TradingPositionState.open) {
			return tradingPosition.currentPrice;
		}
		return current.openPriceInfo?.price;
	};

	const validateTakeProfit = (takeProfit: number) => {
		if (!takeProfit) {
			return false;
		}
		if (tradersGymContext.isActive) {
			takeProfit = gymNormalizePrice(takeProfit, selectedMarketItem?.decPrec);
		}
		const isBuy = side === TradingPositionSide.Buy;
		switch (current.type) {
			case TradingPositionType.Market:
				if (currentPriceQuote) {
					const currentPrice = isBuy ? currentPriceQuote.a : currentPriceQuote.b;
					return isBuy ? takeProfit > currentPrice : currentPrice > takeProfit;
				}
				break;
			case TradingPositionType.Limit:
			case TradingPositionType.Stop:
				const openPrice = getOpenPrice();
				if (openPrice) {
					return isBuy ? takeProfit > openPrice : openPrice > takeProfit;
				}
				break;
			default:
				break;
		}
		return false;
	};

	const validateStopLoss = (stopLoss: number) => {
		if (!stopLoss) {
			return false;
		}
		if (tradersGymContext.isActive) {
			stopLoss = gymNormalizePrice(stopLoss, selectedMarketItem?.decPrec);
		}
		const isBuy = side === TradingPositionSide.Buy;
		switch (current.type) {
			case TradingPositionType.Market:
				if (currentPriceQuote) {
					const currentPrice = isBuy ? currentPriceQuote.a : currentPriceQuote.b;
					return isBuy ? currentPrice > stopLoss : stopLoss > currentPrice;
				}
				break;
			case TradingPositionType.Limit:
			case TradingPositionType.Stop:
				const openPrice = getOpenPrice();
				if (openPrice) {
					return isBuy ? openPrice > stopLoss : stopLoss > openPrice;
				}
				break;
			default:
				break;
		}
		return false;
	};

	const updateAmountInfo = () => {
		const amount = current.amountInfo?.amount;
		if (amount !== undefined) {
			const error = validateQuantity(amount) ? undefined : t('en:INVALID_VALUE');
			setAmountInfo({ error: error });
		}
	};

	const validateQuantity = (amount: number) => {
		const min = current.amountInfo?.min;
		const max = current.amountInfo?.max;
		const step = current.amountInfo?.step;

		// if (tradersGymContext.isActive) {
		// 	const freeMargin = gymContext.tradersGymContext.gymTradingAccount?.freeMargin ?? 0;
		// 	if (freeMargin < marginImpact) {
		// 		return false;
		// 	}
		// }

		if (amount && step && min && max) {
			const isAmountValidByStep = Math.round(amount * 1000000) % Math.round(step * 1000000) === 0;
			return amount >= min && amount <= max && isAmountValidByStep;
		}

		return false;
	};

	const calcPotentialProfitLoss = (price: number, type: TradingPositionLimitType) => {
		if (selectedTradingAccount && selectedMarketItem) {
			const pl = +preCalcPL(
				selectedMarketItem,
				current.amountInfo?.amount,
				current.openPriceInfo?.price || 0,
				price,
				selectedTradingAccount.baseCurrency,
				side,
				selectedTradingAccount
			);

			// set positive/negative styling before formatting pnl value
			if (type === TradingPositionLimitType.TakeProfit) {
				setPotentialProfitColor(pl > 0 ? styles.positiveSubText : styles.negativeSubText);
			} else if (type === TradingPositionLimitType.StopLoss) {
				setPotentialLossColor(pl > 0 ? styles.positiveSubText : styles.negativeSubText);
			}

			return formatAsCurrency(pl);
		}
		return 'N/A';
	};

	const addAdditionalSubPairsCodes = (subCodes: string[]): void => {
		if (selectedTradingAccount && selectedMarketItem) {
			let pairs = getAdditionalSubscriptionPairs(selectedTradingAccount, selectedMarketItem);
			if (pairs) {
				pairs.forEach((pair) => {
					subCodes.push(pair);
				});
			}
		}
	};

	const updateMarginImpact = () => {
		const amount = current.amountInfo?.amount;
		const typeOfOrder = current.type;
		const error = current.amountInfo?.error;
		if (amount && selectedTradingAccount && selectedMarketItem && side && currentPriceQuote && error === undefined) {
			const marginCalcType = getMarginCalculationType(selectedTradingAccount);
			setMarginCalculationType(marginCalcType);

			const openPositions = [];
			for (const positionId in selectedTradingAccount.activePositions) {
				const position = selectedTradingAccount.activePositions[positionId];
				if (position.state === TradingPositionState.open) {
					openPositions.push(position);
				}
			}

			const effectiveSize = getEffectiveSize(
				marginCalcType,
				selectedMarketItem.code,
				selectedTradingAccount.id,
				openPositions
			);
			setCalculatedEffectiveSize(effectiveSize);

			let tradeQuantity = amount;
			if (marginCalcType !== MarginAccountType.Unhedged) {
				tradeQuantity = getHedgedTradeQuantity(effectiveSize, amount, side).value;
			}
			const price =
				typeOfOrder === TradingPositionType.Market ? currentPriceQuote.a : current.openPriceInfo?.price ?? NaN;
			const marginImpact = calculateMarginImpact(
				price,
				tradeQuantity,
				selectedMarketItem,
				selectedTradingAccount,
				side
			);

			// This validation should be available only for Traders Gym
			if (tradersGymContext.isActive) {
				insufficientMargin.current = selectedTradingAccount.freeMargin < marginImpact;
			}

			setMarginUsage(formatAsCurrency(marginImpact));

			const unhedgedMarginImpact = calculateMarginImpact(
				price,
				amount,
				selectedMarketItem,
				selectedTradingAccount,
				side
			);
			setUnhedgedMarginUsage(formatAsCurrency(unhedgedMarginImpact));

			if (marginCalcType !== MarginAccountType.Unhedged) {
				setHedgedSquareQuantity(effectiveSize.majoritySide === 'SQUARE');
			}
		} else {
			setMarginUsage(tt('N/A'));
			if (tradersGymContext.isActive) {
				insufficientMargin.current = false;
			}
		}
	};

	const updatePipCost = () => {
		const amount = current.amountInfo?.amount;
		const error = current.amountInfo?.error;
		if (amount && error === undefined) {
			if (selectedTradingAccount && selectedMarketItem) {
				const val = +calcPipCost(amount, selectedMarketItem, selectedTradingAccount);
				setPipCost(formatAsCurrency(val));
			}
		} else {
			setPipCost(tt('N/A'));
		}
	};

	const updateFreeMargin = () => setFreeMargin(formatAsCurrency(selectedTradingAccount?.freeMargin));

	const formatAsCurrency = (value: number): string =>
		selectedTradingAccount && !isNaN(value) ? formatAsMoney(value) : tt('N/A');

	const calculateLimitStopPrice = (): number | undefined => {
		if (selectedMarketItem && selectedMarketItem.code === prevMarketItemCode) {
			if (currentPriceQuote) {
				const isBuy = side === TradingPositionSide.Buy;
				const isLimit = current.type === TradingPositionType.Limit;
				const price = isBuy ? currentPriceQuote.a : currentPriceQuote.b;
				if ((isBuy && isLimit) || (!isBuy && !isLimit)) {
					return +(price - price * (INITIAL_LIMIT_STOP_PERCENTAGE / 100)).toFixed(selectedMarketItem.decPrec);
				}

				if ((isBuy && !isLimit) || (!isBuy && isLimit)) {
					return +(price + price * (INITIAL_LIMIT_STOP_PERCENTAGE / 100)).toFixed(selectedMarketItem.decPrec);
				}
			}
		}
	};

	const setInitialQuantityGroup = () => {
		if (selectedMarketItem) {
			const quantities: number[] = fillQuantity(selectedMarketItem, selectedTradingAccount);
			// TODO: - Double check how we get the last traded quantity(if exists) for default value...
			const defQuantity = quantities[0];
			setTradeProps({ quantity: defQuantity });
			setQuantityValues(quantities);
		}
	};

	const confirmButtonText = (): string => {
		if (tradingPosition) {
			return t('en:MODIFY');
		}
		return isOneClickTradingEnabled() ? t(`wtr:${side}_NOW`) : t(`en:CONFIRM_${side}`);
	};

	const handleOrderTypeChange = (_event: React.SyntheticEvent<HTMLElement, Event>, { value }: DropdownProps) => {
		if (
			value === TradingPositionType.Market ||
			value === TradingPositionType.Stop ||
			value === TradingPositionType.Limit
		) {
			setTradeProps({ type: value });
		}
	};

	const handleShowCalendar = () => setShowCalendar(true);

	const handleCloseCalendar = () => setShowCalendar(false);

	const handleDateSelection = (date: Date) => {
		setShowCalendar(false);
		setSelectedDate(date);
		formatAndDisplayDate(date);
	};

	const formatAndDisplayDate = (date: Date | undefined | null) => {
		if (date) {
			const newDateText = isJapanAccount
				? getReformattedDate(date as unknown as string)
				: moment(date).format('D/MMM/YYYY');
			setDateText(newDateText);
			setTradeProps({ date: date.getTime() });
		} else {
			setTradeProps({ date: 'EXP_GTC' });
			setDateText(defaultGTC);
		}
	};

	const getReformattedDate = (dateString: string) => {
		if (appContext.isJapanAccount) {
			const timestamp = moment(dateString, 'DD/MM/YYYY', 'en').unix() * 1000;
			if (!isNaN(timestamp)) {
				return getGeneralFormatDate(timestamp, false, true);
			}
		}

		return dateString;
	};

	const handleDateValueChange = (e: any, { value }: any) => {
		const calendarOption = calendarOptions.find((option) => option.value === value);
		setTradeProps({ date: calendarOption?.value! });
		setDateText(calendarOption!.text);
		let date = new Date();
		if (value === 'EXP_DAY' || value === 'EXP_GTC') {
			setSelectedDate(date);
		} else if (value === 'EXP_WEEK') {
			setSelectedDate(moment(date).add(7, 'days').toDate());
		} else if (value === 'EXP_MONTH') {
			setSelectedDate(moment(date).add(1, 'months').toDate());
		}
	};

	// TODO: Rethink and refactor the ticket opening logic - everywhere
	const handleCancelButtonClick = () => {
		// TODO: fix reset animation when component is closed
		isCancellingOrder.current = true;
		setAction(ActionType.unknown);
		setTicketLayout(ticketLayout);
		dashboardContext.showOrderTicket = false;
		dashboardContext.activeIndex = 0;
		dashboardContext.newOrderModalToggle = {
			orderTicket: false,
			confirmOrder: false,
		};
	};

	const handleConfirmButtonCLick = () => {
		if (selectedMarketItem) {
			// Check modify
			const tradeRequest = createTradeRequestObject({ takeProfitPips, stopLossPips }, currentPriceQuote);

			// submit request directly if one-click enabled or limits only is true
			if (isOneClickTradingEnabled() || current.limitsOnly) {
				submitOrderRequest(tradeRequest);
				// TODO: fix reset animation when component is closed
				resetOrderStore();

				// Don't save default pips if the order is created from a Signal
				if (!isSignalOrder) {
					updateUserPrefPips();
				}

				return;
			}

			dashboardContext.tradeInformation = tradeRequest;
			savePreferences();

			if (ticketLayout === TicketLayout.Dock) {
				dashboardContext.showOrderTicket = false;
				dashboardContext.showConfirmTicket = true;
				dashboardContext.newOrderModalToggle = {
					orderTicket: false,
					confirmOrder: false,
				};
			} else {
				dashboardContext.newOrderModalToggle = {
					orderTicket: false,
					confirmOrder: true,
				};
				dashboardContext.showOrderTicket = false;
				dashboardContext.showConfirmTicket = false;
			}
		}
	};

	// TODO: Must be refactored, move this to a helper and separate the dashboardContext from this
	const submitOrderRequest = async (tradeRequest: any) => {
		setAction(ActionType.unknown);
		if (rfpGatewayContext) {
			dashboardContext.orderConfirm.edit = false;
			dashboardContext.orderConfirm.close = false;
			dashboardContext.showOrderTicket = false;

			// new order request
			if (tradeRequest && tradeRequest.posId === undefined) {
				if (tradeRequest && (current.type === TradingPositionType.Limit || current.type === TradingPositionType.Stop)) {
					rfpGatewayContext.send(RFP.newOrder, tradeRequest as NewOrderRequest);
				} else {
					rfpGatewayContext.send(RFP.newTrade, tradeRequest as NewPositionRequest);
				}
				dashboardContext.orderConfirm.new = true;
			}

			// edit order request
			if (tradeRequest && tradeRequest.posId !== undefined) {
				if (tradersGymContext.isActive && tradersGymContext.gymTradingAccount && tradingPosition) {
					const currentPos = tradingPosition as GymTradingPosition;
					const pos = currentPos.deepCopy();
					if (pos) {
						if (pos.state === TradingPositionState.pending && tradeRequest.priceLevel) {
							pos.prc = tradeRequest.priceLevel;
						}
						pos.tp = tradeRequest.limitLevel ?? 0;
						pos.sl = tradeRequest.stopLevel ?? 0;

						GymTradingManager.sharedInstance().modifyPosition(tradersGymContext.gymTradingAccount, pos);
					}
				} else {
					if (current.limitsOnly) {
						if (tradingPosition?.state === TradingPositionState.pending) {
							rfpGatewayContext.send(RFP.editOrder, tradeRequest as EditOrderRequest);
						} else if (tradingPosition?.state === TradingPositionState.open) {
							rfpGatewayContext.send(RFP.editPosition, tradeRequest as EditPositionRequest);
						}
					} else {
						rfpGatewayContext.send(RFP.editOrder, tradeRequest as EditOrderRequest);
					}
				}
				dashboardContext.orderConfirm.edit = true;
			}
		}

		dashboardContext.orderValue.takeProfit = '';
		dashboardContext.newOrderModalToggle = {
			orderTicket: false,
			confirmOrder: false,
		};
		dashboardContext.activeIndex = 0;
		dashboardContext.isEdit = false;
		dashboardContext.getOrderInfo = tradeRequest;
		dashboardContext.tradeInformation = undefined;
		dashboardContext.showConfirmTicket = false;

		let instrumentTrade: any = {};
		let lastTrade: any = JSON.parse(JSON.stringify(instrumentLastTrade));

		if (tradeRequest && tradeRequest.code) {
			instrumentTrade[tradeRequest.code] = {
				quantity: tradeRequest.tradeSize,
				quantityType: quantityType,
				typeOfOrder: tradeRequest.tradeType ? tradeRequest.tradeType : 'market',
				tp: tradeRequest.limitLevel,
				sl: tradeRequest.stopLevel,
			};
		}

		if (lastTrade.length === 0) {
			lastTrade.push(instrumentTrade);
		}

		let i = -1;
		lastTrade.forEach((element: any, index: any) => {
			if (element.hasOwnProperty(tradeRequest.code)) {
				i = index;
			}
		});

		if (i > -1) {
			lastTrade[i] = instrumentTrade;
		} else {
			lastTrade.push(instrumentTrade);
		}
		dashboardContext.instrumentLastTrade = lastTrade;
	};

	const handleOpenPriceChange = (value: number) => {
		const error = validateOpenPrice(value) ? undefined : t('en:INVALID_VALUE');
		setOpenPriceInfo({ isActive: true, price: value, error });
	};

	const handleQuantityChange = (value: number) => {
		const error = validateQuantity(value) ? undefined : t('en:INVALID_VALUE');
		setAmountInfo({ amount: value, error });
		if (
			appContext.userPreferences!.user_prefs.trading.settings[dashboardContext.accountType]?.amount &&
			selectedInstrument
		) {
			appContext.userPreferences!.user_prefs.trading!.settings[dashboardContext.accountType]!.amount![
				selectedInstrument.code
			] = value;
		}
	};

	const handleTakeProfitChange = (value: number) => {
		if (isTakeProfitActive) {
			const error = validateTakeProfit(value) ? undefined : t('en:INVALID_VALUE');
			setTakeProfitInfo({ price: value, error });
		}
	};

	const handleStopLossChange = (value: number) => {
		if (isStopLossActive) {
			const error = validateStopLoss(value) ? undefined : t('en:INVALID_VALUE');
			setStopLossInfo({ price: value, error });
		}
	};

	const handleTakeProfitEnabled = () => {
		setTakeProfitInfo({
			isActive: !isTakeProfitActive,
		});
	};

	const handleStopLossEnabled = () => {
		setStopLossInfo({
			isActive: !isStopLossActive,
		});
	};

	const undockTicketLayout = () => {
		dashboardContext.closeAllOtherTabs();
		setTicketLayout(TicketLayout.Undock);
		dashboardContext.newOrderModalToggle = {
			orderTicket: true,
			confirmOrder: false,
		};
	};

	const dockTicketLayout = () => {
		dashboardContext.closeAllOtherTabs();
		dashboardContext.showOrderTicket = true;
		dashboardContext.startTradingNow = true;
		setTicketLayout(TicketLayout.Dock);

		dashboardContext.newOrderModalToggle = {
			orderTicket: false,
			confirmOrder: false,
		};
	};

	const activeTradingPositions = () => {
		if (tradersGymContext.isActive) {
			return tradersGymContext.gymTradingAccount?.activePositions ?? [];
		}
		return dashboardContext.getTradingPositions();
	};

	// Some margin related checks -> show a pop up (insufficient amount)
	// Returns object indicating whether to show the popup, and if so, which specific message to show
	const isInstrumentOpenPosition = useMemo(() => {
		const error = current.amountInfo?.error;
		if (selectedTradingAccount && selectedMarketItem && error === undefined) {
			const position = activeTradingPositions().filter(
				(position) =>
					position.code === selectedMarketItem.code &&
					position.aId === selectedTradingAccount.id &&
					position.state === TradingPositionState.open
			);

			if (current.type === TradingPositionType.Market) {
				if (
					position.length > 0 &&
					marginCalculationType !== MarginAccountType.Unhedged &&
					!hedgedSquareQuantity &&
					calculatedEffectiveSize.majoritySide !== side
				) {
					return {
						showPopup: true,
						accountType: [marginCalculationType],
						showAmount: true,
						message: tt('MARGIN_USAGE_INFO_MARKET'),
					};
				}
			} else if (current.type === TradingPositionType.Limit || current.type === TradingPositionType.Stop) {
				if (
					position.length > 0 &&
					marginCalculationType !== MarginAccountType.Unhedged &&
					!hedgedSquareQuantity &&
					calculatedEffectiveSize.majoritySide !== side
				) {
					return {
						showPopup: true,
						accountType: [marginCalculationType],
						showAmount: true,
						message: tt('MARGIN_USAGE_INFO_DEDUCTED'),
					};
				} else {
					return {
						showPopup: true,
						accountType: [marginCalculationType],
						showAmount: false,
						message: tt('MARGIN_USAGE_INFO_PENDING'),
					};
				}
			}
		}
		return { showPopup: false, accountType: [], showAmount: false, message: '' };
	}, [
		selectedMarketItem,
		side,
		orderType,
		tradingPosition,
		selectedTradingAccount,
		hedgedSquareQuantity,
		calculatedEffectiveSize,
	]);

	const mustShowRemainingVolume =
		isJapanSubscriptionAccount &&
		isLiveMode &&
		subscriptionInfo?.maxVolume &&
		!checkIsVolumeUnlimited(subscriptionInfo);

	return (
		<ConditionalWrapper
			condition={ticketLayout === TicketLayout.Undock}
			wrapper={(children: any) => (
				<Modal show animation centered dialogClassName={styles.modalDialog} dialogAs={DraggableModalDialog}>
					<Modal.Body className={styles.modalBody}>{children}</Modal.Body>
				</Modal>
			)}
		>
			<div className={styles.container}>
				<InstrumentHeader
					showButtons={false}
					resizeHeader={true}
					headerActions={false}
					undock={ticketLayout === TicketLayout.Undock ? dockTicketLayout : undockTicketLayout}
					onCloseButtonCLick={handleCancelButtonClick}
				/>
				{ticketLayout === TicketLayout.Dock && (
					<div className={styles.sideCloseButton}>
						<CloseButton onClick={handleCancelButtonClick} />
					</div>
				)}
				{openOrderInformation && ticketLayout === TicketLayout.Undock ? (
					<ModalOrderInformation />
				) : (
					<>
						<div
							className={
								ticketLayout === TicketLayout.Dock ? styles.fieldContainer : cn(styles.fieldContainer, styles.undocked)
							}
						>
							{selectedMarketItem && !current.limitsOnly && (
								<div className={styles.fieldGroup}>
									<div className={cn(styles.row, styles.noPadding)}>
										<div>
											<BidAskSpread
												instrument={currentPriceQuote}
												marketItem={selectedMarketItem!}
												order={true}
												typeOfOrder={orderType}
												isBidAskDisabled={tradingPosition !== undefined}
												ticketLayout={ticketLayout}
											/>
										</div>
									</div>
								</div>
							)}

							<div className={styles.fieldGroup}>
								{!current.limitsOnly && (
									<>
										<div className={styles.row}>
											<div className={cn(styles.label)}>{t('en:ORDER_TYPE')}</div>
											<div className={styles.input}>
												<Dropdown
													selection
													options={orderTypeOptions}
													value={orderType}
													className={styles.dropdownMenu}
													onChange={handleOrderTypeChange}
													disabled={tradingPosition?.state === TradingPositionState.pending}
												/>
											</div>
										</div>

										{current.type !== TradingPositionType.Market && (
											<div className={styles.row}>
												<div className={styles.label}>
													{current.type === TradingPositionType.Limit
														? side === TradingPositionSide.Buy
															? t('wtr:BUY_LIMIT')
															: t('wtr:SELL_LIMIT')
														: side === TradingPositionSide.Buy
														? t('wtr:BUY_STOP')
														: t('wtr:SELL_STOP')}
												</div>
												<div className={styles.input}>
													<NumberInput
														lotAware={false}
														incrementor={{
															step: getMarketItemPipSize(selectedMarketItem),
															max: 100000000,
														}}
														value={current.openPriceInfo?.price}
														marketItem={selectedMarketItem}
														onChange={handleOpenPriceChange}
														amountErrorClass={!!current.openPriceInfo?.error}
													/>
													<span className={styles.errorValidation}>{current.openPriceInfo?.error}</span>
												</div>
											</div>
										)}

										{!tradersGymContext.isActive && current.type !== TradingPositionType.Market && (
											<div className={styles.row}>
												<div className={styles.label}>{t('en:EXPIRY_ON_DATE')}</div>
												<div className={styles.input}>
													{showCalendar && (
														<TradeTicketCalendar
															selectedDate={selectedDate}
															handleDateSelection={handleDateSelection}
															handleCloseCalendar={handleCloseCalendar}
															datePickerLocale={datePickerLocale}
														/>
													)}
													<div className={styles.calendarIcon} onClick={handleShowCalendar}>
														<FontAwesomeIcon icon={['far', 'calendar-alt']} size="lg" />
													</div>
													<Dropdown
														selection
														options={calendarOptions}
														className={styles.dropdownMenu}
														onChange={handleDateValueChange}
														value={current.date}
														text={dateText}
														placeholder="Day"
													/>
												</div>
											</div>
										)}

										<div className={styles.row}>
											<div>{tt(isSpreadBettingAccount ? 'POUND_PER_POINT' : `QT_${quantityType.toUpperCase()}`)}</div>
											<div className={styles.input}>
												<NumberInput
													lotAware
													incrementor={{
														quantityValues: quantityValues,
														min: current.amountInfo?.min,
														max: current.amountInfo?.max,
													}}
													roundLot={current.amountInfo?.rndLot}
													marketItem={selectedMarketItem}
													value={current.amountInfo?.amount}
													onChange={handleQuantityChange}
													disabled={tradingPosition?.state === TradingPositionState.pending}
													amountErrorClass={!!current.amountInfo?.error}
												/>
												<span className={styles.errorValidation}>{current.amountInfo?.error}</span>
												{insufficientMargin.current && <div className={styles.noMargin}>{t('wtr:NO_MARGIN')}</div>}
											</div>
										</div>

										{!isSpreadBettingAccount && (
											<div className={cn(styles.row, styles.infoRow)}>
												<div className={styles.label}>{t('en:PIP_COST')}</div>
												<div className={styles.infoLabel}>{pipCost}</div>
											</div>
										)}


										{mustShowRemainingVolume && (
											<div className={cn(styles.row, styles.infoRow)}>
												<div className={styles.label}>{t('wtr:REMAINING_VOLUME')}</div>
												<div className={styles.infoLabel}>{displayVolumeInfo(subscriptionInfo.remainingVolume)}</div>
											</div>
										)}

										<div className={cn(styles.row, styles.infoRow)}>
											<div className={styles.label}>{t('en:EST_MARGIN_USAGE')}</div>
											<div className={styles.infoLabel}>
												{marginRequirementsContext?.doesRulesApply && futureMarginValue ? (
													<>{formatAsCurrency(Math.max(futureMarginValue, 0))}</>
												) : (
													<>
														{ticketLayout === TicketLayout.Dock &&
															isInstrumentOpenPosition.showPopup &&
															isInstrumentOpenPosition.accountType.includes(marginCalculationType) && (
																<WtrPopup
																	content={
																		<div className={styles.popupMessage}>
																			{isInstrumentOpenPosition.showAmount
																				? isInstrumentOpenPosition.message.replace('%s', unhedgedMarginUsage)
																				: isInstrumentOpenPosition.message}
																		</div>
																	}
																	position={isArabic ? 'top left' : 'top right'}
																	trigger={
																		<FontAwesomeIcon
																			icon={['fas', 'info-circle']}
																			style={{
																				fontSize: '16px',
																				color: '#939b9f',
																				marginLeft: '22px',
																				cursor: 'pointer',
																				position: 'relative',
																				right: '9px',
																			}}
																		/>
																	}
																/>
															)}
														{marginUsage}
													</>
												)}
											</div>
										</div>

										<div className={cn(styles.row, styles.infoRow)}>
											<div className={styles.label}>{t('en:FREE_MARGIN')}</div>
											<div className={styles.infoLabel}>{freeMargin}</div>
										</div>
									</>
								)}
								<>
									<LimitProfitLoss
										type={TradingPositionLimitType.TakeProfit}
										value={current.takeProfitInfo?.price ?? NaN}
										potentialProfitLoss={current.takeProfitInfo?.potentialProfitLoss ?? 'N/A'}
										pnlTextColor={potentialProfitColor}
										enabled={isTakeProfitActive}
										marketItem={selectedMarketItem}
										error={current.takeProfitInfo?.error}
										onEnabled={handleTakeProfitEnabled}
										onChange={handleTakeProfitChange}
										openPrice={getOpenPrice() ?? NaN}
									/>

									<LimitProfitLoss
										type={TradingPositionLimitType.StopLoss}
										value={current.stopLossInfo?.price ?? NaN}
										potentialProfitLoss={current.stopLossInfo?.potentialProfitLoss ?? 'N/A'}
										pnlTextColor={potentialLossColor}
										enabled={isStopLossActive}
										marketItem={selectedMarketItem}
										error={current.stopLossInfo?.error}
										onEnabled={handleStopLossEnabled}
										onChange={handleStopLossChange}
										openPrice={getOpenPrice() ?? NaN}
									/>
								</>
							</div>
						</div>
						{isTraderGymActive ? (
							<div className={styles.tradersGymField}>{t('en:TRADERS_GYM_TRADE_TICKET')}</div>
						) : null}
						<div className={styles.footer}>
							<Button
								variant="secondary"
								label={t('en:CANCEL')}
								size="fluid"
								onClick={handleCancelButtonClick}
								smallFont={languageSettings === 'pt-BR' || languageSettings === 'es'}
							/>
							<Button
								variant="primary"
								disabled={
									current.amountInfo?.error !== undefined ||
									current.openPriceInfo?.error !== undefined ||
									(isTakeProfitActive && current.takeProfitInfo?.error !== undefined) ||
									(isStopLossActive && current.stopLossInfo?.error !== undefined) ||
									(isTakeProfitActive && current.takeProfitInfo?.potentialProfitLoss === undefined) ||
									(isStopLossActive && current.stopLossInfo?.potentialProfitLoss === undefined) ||
									insufficientMargin.current ||
									currentPriceQuote === undefined
								}
								size="fluid"
								label={confirmButtonText()}
								onClick={handleConfirmButtonCLick}
								smallFont={languageSettings === 'pt-BR' || languageSettings === 'es'}
							/>
						</div>
					</>
				)}
			</div>
		</ConditionalWrapper>
	);
};

export default TradeTicket;
